import React from "react"

import PageLayout from "../../components/PageLayout/PageLayout"
import { MenuItems } from "../../components/NavMenu/Menu"
import SEO from "../../components/SEO/SEO"
import Blogs from "../../components/Blog/Blogs"

const IndexPage = () => (
  <PageLayout selectedMenu={MenuItems.BLOGS}>
    <SEO
      title="Debojit's Blog"
      description="Collection of random thoughts and works"
      slug="blogs/"
    />
    <Blogs />
  </PageLayout>
)

export default IndexPage
