import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./style.css"

export interface LandingBlogRow {
  id: string
  timeToRead: number
  frontmatter: {
    postThumbnail: string
    title: string
    date: string
    templateKey: string
    description: string
    tags: string[]
  }
  fields: {
    slug: string
  }
}

const Blogs: React.FC<{}> = ({}) => {
  const data: {
    allMarkdownRemark: { nodes: ReadonlyArray<LandingBlogRow> }
  } = useStaticQuery(graphql`
    query allBlogs {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        nodes {
          frontmatter {
            postThumbnail
            date
            description
            tags
            templateKey
            title
          }
          id
          timeToRead
          fields {
            slug
          }
        }
      }
    }
  `)

  return (
    <section id="blogs" className="blogs-page">
      <div className="container">
        <header className="page-header">
          <h1>Debojit's Blog</h1>
          <p>Collection of random thoughts and works</p>
        </header>
        <div className="grids">
          {data.allMarkdownRemark.nodes.map(row => {
            const publishDate = new Date(Date.parse(row.frontmatter.date))

            return (
              <article key={row.id} className="card " data-aos="fade-up">
                <Link to={row.fields.slug}>
                  {!!row.frontmatter.postThumbnail && (
                    <img
                      src={row.frontmatter.postThumbnail}
                      alt={row.frontmatter.title + "- Featured Shot"}
                    />
                  )}
                </Link>
                <header>
                  <h2 className="post-title">
                    <Link to={row.fields.slug} className="post-link">
                      {row.frontmatter.title}
                    </Link>
                  </h2>
                  <div className="post-meta">{publishDate.toDateString()}</div>
                </header>
              </article>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Blogs
